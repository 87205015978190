import * as THREE from "three";
import React, { useState } from "react";

export default function HoldHighlights({ boulderProblem, type }) {
  const [rerender, setRerender] = useState(0); // eslint-disable-line

  return boulderProblem.holds.map((x, i) => {
    const onClickRing = (hold) => {
      if (type !== "readOnly") {
        hold.changeType(type);
        // rerender scene
        setRerender((value) => value + 1);
      }
    };
    let tempArgs = [...x.args];
    tempArgs[0] = 0;
    const tempGeometry = new THREE.RingBufferGeometry(...tempArgs);
    const geometry = new THREE.RingBufferGeometry(...x.args);
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(x.color),
      side: THREE.FrontSide, // TODO change to FrontSide
    });

    return (
      < mesh key={`parent-${i}`}>
        <mesh
          onClick={() => onClickRing(x)}
          key={`temp-${i}`}
          position={x.position}
          rotation={x.rotation}
          geometry={tempGeometry}
          material={
            new THREE.MeshBasicMaterial({
              transparent: true,
              opacity: 0.0,
              side: THREE.FrontSide,
            })
          }
        />

        <mesh
          key={i}
          position={x.position}
          rotation={x.rotation}
          geometry={geometry}
          material={material}
        />
      </mesh>
    );
  });
}

import React from "react";
import { useHistory } from "react-router-dom";
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { CheckCircle, HourglassEmptyRounded } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import CommonStyles from "../utils/CommonStyles";
import { grades } from "../constants";

const styles = (theme) => ({
  ...CommonStyles(theme),
  listOfBoulders: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

function BoulderProblemList({ classes, listOfProblems, title }) {
  const history = useHistory();

  const redirectToProblem = (uuid) => {
    history.push(`route/${uuid}`);
  };
  return (
    <Paper
      style={{
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      {listOfProblems.length === 0 ? (
        <Typography>No boulders</Typography>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }} align="left">
                  Grade
                </TableCell>
                <TableCell style={{ fontWeight: "600" }} align="left">
                  Name
                </TableCell>
                <TableCell style={{ fontWeight: "600" }} align="right">
                  Verified
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfProblems.map((row) => (
                <TableRow
                  key={row.uuid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  className={classes.listOfBoulders}
                  onClick={() => redirectToProblem(row.uuid)}
                >
                  <TableCell align="left" style={{ width: 10 }}>
                    V{row.grade}/{grades[row.grade]}
                  </TableCell>
                  <TableCell style={{ width: 500 }} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.active ? (
                      <Tooltip title="Verified">
                        <CheckCircle />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Waiting for verification">
                        <HourglassEmptyRounded />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default withStyles(styles)(BoulderProblemList);

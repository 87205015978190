import React from "react";
import Routes from "./Routes";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { blue, grey, red, pink, green } from "@material-ui/core/colors";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import "./App.css";
import CookieConsent from "react-cookie-consent";

const theme = createTheme({
  palette: {
    secondary: {
      main: pink[500],
    },
    primary: {
      main: red[700],
    },
    success: {
      main: blue[700],
    },
    info: {
      main: green[700],
    },
    neutral: {
      main: grey[200],
      text: grey[600],
      medium: grey[400],
    },
    topbar: {
      main: "#a6908f",
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Lato:wght@300"].join(","),
  },
});

function App() {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f0f9ff",
      }}
    >
      <Authenticator.Provider>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </Authenticator.Provider>
      <CookieConsent
        style={{ background: "#e8e8e8", color: "black" }}
        buttonStyle={{ background: "#f66f2d", color: "white" }}
      >
        <b>Notice.</b> Wspinappka uses cookies to provide necessary website
        functionality, improve your experience and analyze our traffic. By using
        our website, you agree to our{" "}
        <a href="/privacy-policy">Privacy Policy</a>, our{" "}
        <a href="/cookies-policy">Cookies Policy</a>, and{" "}
        <a href="/terms">Terms</a>
      </CookieConsent>
    </div>
  );
}

export default App;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_ID;

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send("pageview");
}


Amplify.configure({
  Auth: {
    region: process.env["REACT_APP_REGION"],
    userPoolId: process.env["REACT_APP_USER_POOL_ID"],
    userPoolWebClientId: process.env["REACT_APP_USER_POOL_WEB_CLIENT_ID"],
    mandatorySignIn: false,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: "wspinappka.auth.eu-central-1.amazoncognito.com",
      scope: ["email", "profile", "openid"],
      redirectSignIn:
        process.env.NODE_ENV === "production"
          ? "https://www.wspinappka.pl/"
          : "https://localhost:3000/",
      redirectSignOut:
        process.env.NODE_ENV === "production"
          ? "https://www.wspinappka.pl"
          : "https://localhost:3000",
      responseType: "code",
    },
  },
});

ReactDOM.render(
  <React.StrictMode style={{ height: "100%" }}>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

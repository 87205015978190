import React from "react";
import { CheckCircle, Error, Info, Close, Warning } from "@material-ui/icons";
import { green, amber, red, blue } from "@material-ui/core/colors";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    marginTop: 8,
  },
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    key,
    ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], classes[className])}
      aria-describedby="client-snackbar"
      key={key}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Close className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}


const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

function Notify({items, handleClose})  {
    const open = true
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          {items.map((item, id) => {
            return (
              <MySnackbarContentWrapper
                onClose={() => handleClose(item)}
                variant={item.type}
                message={item.message}
                className={"margin"}
                key={id}
              />
            );
          })}
        </div>
      </Snackbar>
    );
  }



export default withStyles(styles)(Notify);

import {
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  useRadioGroup,
  Paper,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Model3d from "./Model3d";
import { Hold, BoulderProblem } from "../objects";
import { grades, holdsJson } from "../constants";
import API_URL from "../Api";
import Notify from "../utils/Notify";
import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import CommonStyles from "../utils/CommonStyles";

const styles = (theme) => ({
  ...CommonStyles(theme),
  info: {
    margin: "5px",
    padding: "1px 5px",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "5px 0",
    width: "100%",
    maxWidth: "500px",
  },
  ModelContainer: {
    width: "100%",
    position: "relative",
    margin: "10px 0px",
    flex: "1",
  },
  Container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "10px",
  },
  NextButton: {
    width: "100%",
    maxWidth: "500px",
    backgroundColor: "white",
    margin: "5px 0 ",
  },
});

function CreateBoulderProblem({ classes, user }) {
  const history = useHistory();
  const cameraPosition = [5, 2, 3]
  const [stage, setStage] = useState("selectStartHolds");
  const [boulderProblem, setBoulderProblem] = useState(undefined);
  const [grade, setGrade] = useState(0);
  const [name, setName] = useState("");
  const [notifications, setNotifications] = useState([]);
  const notification = (notification) => {
    setNotifications((prevState) => [...prevState, notification]);
    setTimeout(() => {
      closeNotification(notification);
    }, 5000);
  };

  const closeNotification = (item) => {
    setNotifications(notifications.filter((value, i) => value !== item));
  };
  

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
    if (radioGroup.value === "top") {
      boulderProblem.lastIsHold = false;
    } else {
      boulderProblem.lastIsHold = true;
    }

    return <FormControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    const holds = holdsJson.map(
      (x) => new Hold(x.position, x.rotation, x.args, x.type)
    );
    setBoulderProblem(new BoulderProblem(holds));
  }, []);

  useEffect(() => {
    if (stage !== "selectStartHolds") {
      try {
        boulderProblem.checkStartHolds();
      } catch (error) {
        notification({
          type: "error",
          message: "Something went wrong, please try again.",
        });
        history.push(`/`);
      }
    }
  }, [stage]); // eslint-disable-line

  const onSubmit = () => {
    const session = user.signInUserSession;
    try {
      boulderProblem.validateProblem();

      fetch(`${API_URL}/boulderProblems`, {
        method: "POST",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: session.idToken.jwtToken,
        },
        body: JSON.stringify(boulderProblem.toJson()),
      })
        .then((data) => {
          if (data.ok) {
            return data.json();
          } else {
            return data.json().then((res) => {
              throw new Error(res.message);
            });
          }
        })
        .then((res) => {
          notification({
            type: "success",
            message: "Boulder was added successfully!",
          });
          history.push(`/route/${res.uuid}`);
        })
        .catch((error) => {
          notification({
            type: "error",
            message: error.message,
          });
        });
    } catch (error) {
      notification({
        type: "error",
        message: error.message,
      });
    }
  };

  const onClickNext = (nextStage) => {
    try {
      setStage(nextStage);
    } catch (e) {
      console.error(e);
    }
  };

  const chooseAvailableHolds = () => {
    return (
      <div className={classes.Container}>
        <Paper className={classes.infoContainer}>
          <Typography className={classes.info}>
            Choose available holds (for hands and feets)
          </Typography>
        </Paper>
        <Paper className={classes.ModelContainer}>
          <Model3d type="available" boulderProblem={boulderProblem} cameraPosition={cameraPosition} />
        </Paper>
        <Button
          onClick={() => {
            onClickNext("fillInfo");
          }}
          variant="outlined"
          className={classes.NextButton}
        >
          Next
        </Button>
      </div>
    );
  };

  const chooseStartHolds = () => {
    return (
      <div className={classes.Container}>
        <Paper className={classes.infoContainer}>
          <Typography className={classes.info}>Choose start holds</Typography>
        </Paper>
        <Paper className={classes.ModelContainer}>
          <Model3d type="start" boulderProblem={boulderProblem} cameraPosition={cameraPosition} />
        </Paper>
        <Button
          onClick={() => {
            try {
              boulderProblem.checkStartHolds();
              onClickNext("chooseEnding");
            } catch (error) {
              notification({
                type: "error",
                message: error.message,
              });
            }
          }}
          variant="outlined"
          className={classes.NextButton}
        >
          Next
        </Button>
      </div>
    );
  };

  const chooseEndHolds = () => {
    return (
      <div className={classes.Container}>
        <Paper className={classes.infoContainer}>
          <Typography className={classes.info}>Choose end holds</Typography>
        </Paper>
        <Paper className={classes.ModelContainer}>
          <Model3d type="end" boulderProblem={boulderProblem} cameraPosition={cameraPosition} />
        </Paper>
        <Button
          onClick={() => {
            try {
              boulderProblem.checkEndHold();
              onClickNext("selectAvailableHolds");
            } catch (error) {
              notification({
                type: "error",
                message: error.message,
              });
            }
          }}
          variant="outlined"
          className={classes.NextButton}
        >
          Next
        </Button>
      </div>
    );
  };

  const chooseEnding = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper className={classes.infoContainer}>
          <Typography className={classes.info}>
            Now you need to choose if you want climber to climb till he reaches
            some hold or he need to go on top of the wall.
          </Typography>
        </Paper>
        <Paper
          className={classes.infoContainer}
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <Typography className={classes.info}>Choose ending:</Typography>
          <RadioGroup name="use-radio-group" defaultValue="hold">
            <MyFormControlLabel value="hold" label="Hold" control={<Radio />} />
            <MyFormControlLabel value="top" label="Top" control={<Radio />} />
          </RadioGroup>
        </Paper>
        <Button
          className={classes.NextButton}
          onClick={() =>
            setStage(
              boulderProblem.lastIsHold
                ? "selectEndHold"
                : "selectAvailableHolds"
            )
          }
          variant="outlined"
        >
          Next
        </Button>
      </div>
    );
  };

  const fillInfo = () => {
    return (
      <div className={classes.Container}>
        <Paper className={classes.infoContainer}>
          <Typography className={classes.info}>Fill info:</Typography>
        </Paper>
        <TextField
          value={name}
          onChange={(e) => {
            boulderProblem.name = e.target.value;
            setName(boulderProblem.name);
          }}
          label="Problem name"
          variant="outlined"
          className={classes.NextButton}
        />
        <FormControl style={{ maxWidth: "500px", width: "100%" }}>
          <InputLabel id="grade-select-label" variant="outlined">
            Grade
          </InputLabel>
          <Select
            labelId="grade-select-label"
            id="grade-select"
            value={grade}
            label="Grade"
            onChange={(e) => {
              boulderProblem.setGrade(e.target.value);
              setGrade(boulderProblem.grade);
            }}
            variant="outlined"
            className={classes.NextButton}
          >
            {Array.from({ length: 13 }, (_, i) => i).map((x, index) => (
              <MenuItem key={index} value={x}>
                V{x}/{grades[x]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          onClick={() => onSubmit()}
          className={classes.NextButton}
        >
          Submit
        </Button>
      </div>
    );
  };

  const renderStage = () => {
    switch (stage) {
      case "selectStartHolds":
        return chooseStartHolds();
      case "chooseEnding":
        return chooseEnding();
      case "selectEndHold":
        return chooseEndHolds();
      case "selectAvailableHolds":
        return chooseAvailableHolds();
      case "fillInfo":
        return fillInfo();
      default:
        return <div>Error</div>;
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Notify
            items={notifications}
            handleClose={closeNotification}
          />
      {boulderProblem ? (
        renderStage()
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(CreateBoulderProblem);

export const holdTypes = {
  start: "green",
  end: "red",
  available: "blue",
  unused: "grey",
};

export class Hold {
  constructor(position, rotation, args, type) {
    this.position = position;
    this.rotation = rotation;
    this.args = args;
    this.type = type;
    this.color = holdTypes[type];
  }
  changeType(newType) {
    if (this.type === "unused") {
      this.type = newType;
    } else if (this.type === newType) {
      this.type = "unused";
    } else {
      console.error("changeType func error");
    }
    this.color = holdTypes[this.type];
  }
  toJson() {
    return {
      type: this.type,
      position: this.position.toString(),
      args: this.args.toString(),
      rotation: this.rotation.toString(),
    };
  }
}

export class BoulderProblem {
  constructor(holds, name, grade, lastIsHold, author) {
    if (name === undefined) {
      this.holds = holds;
      this.lastIsHold = true;
      this.name = "";
      this.grade = 1;
    } else {
      this.holds = holds;
      this.lastIsHold = lastIsHold;
      this.name = name;
      this.grade = grade;
      this.author = author;
    }
  }

  setGrade(grade) {
    this.grade = grade;
  }

  checkStartHolds() {
    let isValid = false;
    isValid =
      this.holds.filter((x) => x.type === "start").length === 1 ||
      this.holds.filter((x) => x.type === "start").length === 2;
    if (!isValid) {
      throw new Error("Wrong start hold/s! 1 or 2 holds are required.");
    }
  }

  checkEndHold() {
    let isValid = false;
    if (this.lastIsHold) {
      isValid = this.holds.filter((x) => x.type === "end").length === 1;
      if (!isValid) {
        throw new Error("You need to select only 1 top hold");
      }
    } else {
      isValid = this.holds.filter((x) => x.type === "end").length === 0;
      if (!isValid) {
        throw new Error("There shouldn't be any top hold selected");
      }
    }
  }

  checkName() {
    if (this.name.length < 4) {
      throw new Error("Name too short, it needs at least 4 characters!");
    }
  }

  validateProblem() {
    this.checkStartHolds();
    this.checkEndHold();
    this.checkName();
  }

  toJson() {
    const response = {
      name: this.name,
      lastIsHold: this.lastIsHold,
      grade: this.grade,
      holds: this.holds
        .filter((x) => x.type !== "unused")
        .map((x) => x.toJson()),
    };
    return response;
  }
}

export const grades = {
  0: "4",
  1: "5A",
  2: "5B",
  3: "5C",
  4: "6A",
  5: "6B",
  6: "6C",
  7: "7A",
  8: "7B",
  9: "7C",
  10: "8A",
  11: "8B",
  12: "8C",
};
export const holdsJson = [
  // first section
  {
    position: [0.605, 1.08, -1.42],
    rotation: [0, Math.PI / 1.85, -Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [0.594, 1.035, -1.515],
    rotation: [0, Math.PI / 1.8, -Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [0.614, 0.935, -1.29],
    rotation: [Math.PI / 1.2, Math.PI / 2.2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.605, 0.795, -1.25],
    rotation: [Math.PI / 1.2, Math.PI / 2.2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.581, 0.7, -1.425],
    rotation: [Math.PI, Math.PI / 2.2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.558, 0.67, -1.55],
    rotation: [Math.PI, Math.PI / 2.2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.56, 0.865, -1.68],
    rotation: [Math.PI / 1.2, Math.PI / 2.6, -Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [0.592, 0.36, -1.425],
    rotation: [Math.PI / 0.92, Math.PI / 2.25, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.604, 0.495, -1.294],
    rotation: [Math.PI, Math.PI / 2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.603, 0.225, -1.23],
    rotation: [Math.PI, Math.PI / 2, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.602, 0.085, -1.28],
    rotation: [Math.PI, Math.PI / 2.1, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.562, 0.18, -1.61],
    rotation: [Math.PI / 1.2, Math.PI / 2.3, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.405, 0.345, -2.009],
    rotation: [Math.PI / 0.97, Math.PI / 3.6, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.436, 0.04, -2],
    rotation: [Math.PI / 0.97, Math.PI / 3.3, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.437, 1.045, -1.99],
    rotation: [Math.PI / 1.08, Math.PI / 6, -Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.3, 0.72, -2.03],
    rotation: [Math.PI / 1, Math.PI / 18, -Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  // 2nd section
  {
    position: [-0.195, 0.165, -1.3],
    rotation: [Math.PI / 0.78, -Math.PI / 1.5, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.114, 0.07, -1.045],
    rotation: [Math.PI / 1.02, -Math.PI / 1.9, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.305, 0.41, -1.15],
    rotation: [Math.PI / 0.82, -Math.PI / 1.35, Math.PI],
    args: [0.04, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.217, 0.135, -1.46],
    rotation: [Math.PI / 0.7, -Math.PI / 1.5, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.303, 0.27, -1.62],
    rotation: [Math.PI / 1.2, -Math.PI / 2.5, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.3, 0.5, -1.75],
    rotation: [Math.PI / 1.11, -Math.PI / 4.6, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.377, 0.528, -1.44],
    rotation: [Math.PI / 1.3, -Math.PI / 2.5, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.402, 0.54, -1.32],
    rotation: [Math.PI / 1.1, -Math.PI / 2.2, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.401, 0.68, -1.29],
    rotation: [Math.PI / 0.77, -Math.PI / 2.25, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.344, 0.94, -1.285],
    rotation: [Math.PI / 0.68, -Math.PI / 2.4, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.359, 0.9, -1.57],
    rotation: [Math.PI / 0.89, -Math.PI / 2.29, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.33, 1.07, -1.57],
    rotation: [Math.PI / 0.65, -Math.PI / 2.2, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.338, 0.8, -1.62],
    rotation: [Math.PI / 1.05, -Math.PI / 2.6, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  //3rd section
  {
    position: [-0.159, 0.93, -1.067],
    rotation: [Math.PI / 1.05, -Math.PI / 1.05, Math.PI],
    args: [0.045, 0.06, 32],
    type: "unused",
  },
  {
    position: [0.04, 0.53, -1.004],
    rotation: [Math.PI / 1, Math.PI / 1.04, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.055, 0.155, -1.014],
    rotation: [Math.PI / 1, Math.PI / 1.12, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.505, 0.14, -1.065],
    rotation: [Math.PI / 0.98, Math.PI / 1.03, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  //4th section
  {
    position: [0.018, 0.145, -0.683],
    rotation: [Math.PI * 2, Math.PI / 1.01, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.06, 0.535, -0.68],
    rotation: [Math.PI * 2, Math.PI / 1.01, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0, 0.965, -0.683],
    rotation: [Math.PI * 2.01, Math.PI / 0.98, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [0.01, 1.09, -0.672],
    rotation: [Math.PI * 2.03, Math.PI / 0.98, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  //5th section
  {
    position: [-0.31, 1.01, -0.553],
    rotation: [Math.PI / 1.02, -Math.PI / 5.2, Math.PI],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.108, 0.475, -0.545],
    rotation: [Math.PI / 1.3, -Math.PI / 2.6, Math.PI],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.113, 0.454, -0.407],
    rotation: [2.786, -1.448, 3.101],
    args: [0.03, 0.045, 32],
    type: "unused",
  },
  {
    position: [-0.185, 0.446, -0.312],
    rotation: [3.216, -0.648, 3.111],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.286, 0.682, -0.317],
    rotation: [2.586, -0.828, 3.111],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.375, 0.755, -0.211],
    rotation: [2.676, -1.098, 2.971],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.337, 0.861, -0.328],
    rotation: [2.796, -1.028, 2.971],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.463, 0.943, -0.202],
    rotation: [2.406, -0.808, 3.001],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.633, 1.017, -0.044],
    rotation: [2.327, -0.888, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.758, 1.045, 0.089],
    rotation: [3.157, -1.298, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.466, 0.756, -0.034],
    rotation: [2.647, -1.118, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.448, 0.656, -0.033],
    rotation: [2.997, -1.088, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.428, 0.512, -0.021],
    rotation: [2.947, -0.998, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.41, 0.182, 0.049],
    rotation: [2.717, -1.308, 3.102],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.389, 0.101, 0.007],
    rotation: [2.637, -1.308, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.357, 0.149, -0.103],
    rotation: [2.897, -1.098, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.314, 0.238, -0.181],
    rotation: [3.007, -0.938, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.234, 0.086, -0.262],
    rotation: [3.087, -0.778, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.036, 0.073, -0.419],
    rotation: [2.657, -1.428, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.455, 0.095, 0.222],
    rotation: [2.777, -0.628, 3.102],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.602, 0.025, 0.35],
    rotation: [2.747, -0.538, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.661, 0.639, 0.22],
    rotation: [2.707, -0.538, 2.932],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.757, 0.892, 0.151],
    rotation: [2.507, -0.848, 2.932],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  // 6th section
  {
    position: [-0.783, 0.799, 0.288],
    rotation: [3.127, -1.168, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.091, 0.82, 0.546],
    rotation: [3.217, -0.908, 2.932],
    args: [0.025, 0.04000000000000001, 32],
    type: "unused",
  },
  {
    position: [-1.135, 0.718, 0.698],
    rotation: [5.107, -1.808, 2.962],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.181, 0.942, 0.846],
    rotation: [4.507, -1.718, 2.962],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.167, 0.937, 0.983],
    rotation: [4.247, -1.728, 2.962],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.111, 0.736, 0.964],
    rotation: [4.527, -2.068, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.078, 0.622, 0.747],
    rotation: [4.567, -2.228, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.924, 0.496, 0.425],
    rotation: [6.247, -2.768, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.864, 0.376, 0.409],
    rotation: [6.027, -2.718, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.728, 0.112, 0.412],
    rotation: [5.767, -2.398, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.725, 0.076, 0.549],
    rotation: [7.467, -0.658, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.949, 0.32, 0.519],
    rotation: [7.717, -1.088, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.957, 0.422, 0.835],
    rotation: [7.747, -1.128, 2.962],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.973, 0.473, 0.982],
    rotation: [7.727, -1.218, 2.962],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.886, 0.339, 0.956],
    rotation: [7.497, -0.898, 3.032],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.831, 0.241, 0.845],
    rotation: [7.557, -0.708, 2.992],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.777, 0.155, 0.681],
    rotation: [7.607, -0.638, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.67, 0.081, 0.841],
    rotation: [7.767, -1.118, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.63, 0.054, 1.214],
    rotation: [7.397, -1.268, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.814, 0.257, 1.247],
    rotation: [8.047, -0.918, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.785, 0.258, 1.119],
    rotation: [8.037, -1.008, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.917, 0.424, 1.251],
    rotation: [8.177, -1.058, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.999, 0.608, 1.253],
    rotation: [7.857, -1.238, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.042, 0.688, 1.156],
    rotation: [7.317, -0.958, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.147, 0.947, 1.23],
    rotation: [7.107, -1.498, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.22, 1.155, 0.972],
    rotation: [7.957, -1.258, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.246, 1.282, 0.721],
    rotation: [8.797, -1.358, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.141, 1.028, 0.613],
    rotation: [9.347, -1.008, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.141, 1.326, 0.546],
    rotation: [9.647, -0.558, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.957, 1.168, 0.389],
    rotation: [9.417, -0.648, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.888, 1.262, 0.361],
    rotation: [9.687, -0.548, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.257, 1.423, 0.756],
    rotation: [8.067, -1.548, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.291, 1.325, 1.152],
    rotation: [8.237, -1.398, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.311, 1.414, 1.288],
    rotation: [9.637, -1.628, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.298, 1.333, 1.323],
    rotation: [10.117, -1.838, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.225, 1.176, 1.323],
    rotation: [10.807, -2.108, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.901, 0.378, 1.427],
    rotation: [10.857, -1.938, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.927, 0.454, 1.524],
    rotation: [10.857, -1.988, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.976, 0.571, 1.51],
    rotation: [10.937, -1.988, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.996, 0.602, 1.426],
    rotation: [10.837, -1.968, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.064, 0.705, 1.478],
    rotation: [11.177, -2.128, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.133, 0.807, 1.448],
    rotation: [11.037, -2.128, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-1.155, 0.926, 1.437],
    rotation: [10.917, -1.428, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.217, 1.128, 1.529],
    rotation: [10.987, -2.068, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.253, 1.218, 1.544],
    rotation: [10.197, -2.078, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.2, 1.325, 1.633],
    rotation: [9.467, -2.498, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.182, 1.154, 1.621],
    rotation: [9.617, -2.568, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.041, 0.874, 1.651],
    rotation: [9.637, -2.628, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.929, 0.592, 1.679],
    rotation: [9.647, -2.598, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.824, 0.322, 1.691],
    rotation: [10.067, -2.248, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.675, 0.12, 1.538],
    rotation: [10.737, -2.488, 2.942],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.758, 0.352, 1.769],
    rotation: [9.437, -2.448, 2.942],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.726, 0.482, 1.79],
    rotation: [9.457, -2.658, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.577, 0.032, 1.968],
    rotation: [10.227, -2.118, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.573, 0.137, 2.016],
    rotation: [9.417, -2.148, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.605, 0.24, 1.973],
    rotation: [9.727, -1.948, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.632, 0.463, 1.989],
    rotation: [10.487, -2.018, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.707, 0.7, 1.972],
    rotation: [10.067, -1.818, 3.002],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.772, 0.998, 2.035],
    rotation: [10.697, -1.868, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.873, 1.353, 2.043],
    rotation: [10.447, -2.038, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.955, 1.498, 1.834],
    rotation: [12.087, -1.218, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-1.11, 1.448, 1.689],
    rotation: [11.557, -0.408, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },

  //7th section
  {
    position: [-0.895, 1.48, 2.245],
    rotation: [13.517, -1.108, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.786, 1.369, 2.359],
    rotation: [13.297, -0.978, 3.002],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.716, 1.381, 2.507],
    rotation: [13.337, -0.968, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.745, 1.23, 2.332],
    rotation: [13.067, -1.078, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.761, 1.133, 2.237],
    rotation: [13.127, -1.058, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.728, 0.998, 2.306],
    rotation: [13.067, -0.968, 3.002],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.646, 1.025, 2.406],
    rotation: [12.737, -0.978, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.634, 0.865, 2.402],
    rotation: [12.757, -0.958, 3.002],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.637, 0.736, 2.274],
    rotation: [13.657, -0.868, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.548, 0.726, 2.493],
    rotation: [13.307, -0.848, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.459, 0.426, 2.328],
    rotation: [13.347, -1.248, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.497, 0.396, 2.183],
    rotation: [12.807, -1.068, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.421, 0.344, 2.382],
    rotation: [13.097, -1.158, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.498, 0.282, 2.161],
    rotation: [12.597, -1.088, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.468, 0.192, 2.224],
    rotation: [12.957, -1.198, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.477, 0.052, 2.175],
    rotation: [12.967, -1.108, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.404, 0.076, 2.287],
    rotation: [12.957, -0.698, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  //8th section
  {
    position: [-0.172, 0.061, 2.359],
    rotation: [13.357, -0.378, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0, 0.036, 2.394],
    rotation: [13.367, -0.128, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.009, 0.178, 2.54],
    rotation: [13.267, -0.228, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.086, 0.18, 2.513],
    rotation: [13.217, -0.278, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.311, 0.216, 2.432],
    rotation: [13.177, -0.228, 3.062],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.301, 0.327, 2.512],
    rotation: [13.147, -0.448, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.16, 0.291, 2.559],
    rotation: [13.157, -0.448, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.16, 0.293, 2.555],
    rotation: [13.187, -0.448, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.023, 0.306, 2.638],
    rotation: [13.087, -0.108, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.019, 0.396, 2.681],
    rotation: [12.967, -0.248, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.089, 0.395, 2.645],
    rotation: [12.877, -0.348, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.014, 0.494, 2.708],
    rotation: [12.887, -0.278, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.123, 0.484, 2.662],
    rotation: [12.877, -0.398, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.34, 0.432, 2.558],
    rotation: [13.107, -0.238, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.339, 0.625, 2.718],
    rotation: [13.397, -0.218, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.405, 0.696, 2.793],
    rotation: [13.397, -0.228, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.444, 0.603, 2.579],
    rotation: [13.377, -1.058, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.553, 0.794, 2.808],
    rotation: [14.067, -0.898, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.565, 0.849, 2.732],
    rotation: [14.787, -1.068, 2.972],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.564, 0.929, 2.635],
    rotation: [14.837, -1.288, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.614, 0.934, 2.825],
    rotation: [15.127, -1.208, 2.972],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.639, 0.983, 2.99],
    rotation: [15.057, -1.458, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.542, 0.83, 2.976],
    rotation: [13.857, -0.768, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.673, 1.103, 3.249],
    rotation: [11.857, -1.178, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.649, 1.153, 3.014],
    rotation: [12.397, -1.778, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.639, 1.351, 2.901],
    rotation: [11.427, -1.778, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.645, 1.378, 2.749],
    rotation: [10.747, -1.898, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.675, 1.426, 3.201],
    rotation: [10.537, -1.638, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.449, 1.579, 3.323],
    rotation: [8.777, -2.638, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.333, 1.586, 3.412],
    rotation: [9.017, -2.558, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.4, 1.424, 3.427],
    rotation: [9.217, -2.638, 2.972],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.5, 1.335, 3.391],
    rotation: [9.167, -2.678, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.378, 1.308, 3.42],
    rotation: [9.907, -3.008, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.19, 1.355, 3.467],
    rotation: [9.657, -3.008, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.13, 1.258, 3.411],
    rotation: [10.317, -3.378, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.322, 1.201, 3.359],
    rotation: [10.037, -3.178, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.418, 1.148, 3.336],
    rotation: [9.937, -3.188, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.575, 1.021, 3.284],
    rotation: [10.067, -3.188, 2.972],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.431, 1.03, 3.205],
    rotation: [10.397, -3.438, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.313, 1.092, 3.23],
    rotation: [10.457, -3.408, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.335, 0.96, 3.002],
    rotation: [10.057, -3.188, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.431, 0.945, 3.041],
    rotation: [10.267, -3.898, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.519, 0.882, 3.084],
    rotation: [10.287, -3.788, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.364, 0.84, 2.928],
    rotation: [10.047, -3.208, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.159, 0.843, 2.983],
    rotation: [9.947, -2.688, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.2, 0.944, 3.017],
    rotation: [9.947, -2.698, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.203, 0.683, 2.834],
    rotation: [10.247, -2.918, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.222, 0.597, 2.705],
    rotation: [10.347, -3.078, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.248, 0.439, 2.591],
    rotation: [9.877, -2.818, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.073, 0.609, 2.761],
    rotation: [10.307, -2.948, 2.932],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [-0.073, 0.732, 2.927],
    rotation: [10.327, -2.948, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.061, 0.856, 3.052],
    rotation: [10.177, -2.678, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.056, 0.939, 3.124],
    rotation: [10.117, -2.448, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.069, 1.084, 3.198],
    rotation: [9.977, -2.418, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.012, 1.145, 3.308],
    rotation: [10.067, -2.528, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.016, 1.148, 3.306],
    rotation: [10.127, -2.468, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.056, 1.257, 3.504],
    rotation: [10.627, -2.528, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [-0.004, 1.365, 3.591],
    rotation: [10.317, -2.738, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [-0.059, 1.557, 3.604],
    rotation: [9.177, -2.318, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  //9th section
  {
    position: [0.066, 1.548, 3.659],
    rotation: [9.047, -3.188, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.361, 1.567, 3.535],
    rotation: [8.897, -3.678, 2.932],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.329, 1.387, 3.585],
    rotation: [9.837, -3.708, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.408, 1.419, 3.546],
    rotation: [9.697, -3.668, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.379, 1.232, 3.472],
    rotation: [9.597, -3.198, 2.932],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.311, 1.146, 3.463],
    rotation: [9.737, -3.418, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.044, 0.966, 3.194],
    rotation: [10.057, -3.038, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.129, 0.919, 3.169],
    rotation: [10.097, -3.258, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.102, 0.794, 3.06],
    rotation: [10.427, -3.258, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.086, 0.67, 2.852],
    rotation: [10.237, -3.128, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.199, 0.6, 2.798],
    rotation: [10.117, -3.238, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.199, 0.465, 2.695],
    rotation: [9.917, -3.308, 2.932],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.207, 0.322, 2.585],
    rotation: [10.157, -3.678, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.162, 0.235, 2.562],
    rotation: [9.917, -3.678, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.136, 0.088, 2.467],
    rotation: [10.307, -3.158, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.234, 0.101, 2.462],
    rotation: [10.197, -3.188, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.338, 0.009, 2.376],
    rotation: [9.997, -3.268, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.342, 0.153, 2.434],
    rotation: [9.617, -3.788, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.242, 0.2, 2.496],
    rotation: [9.667, -3.658, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.378, 0.318, 2.449],
    rotation: [9.707, -3.778, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.251, 0.397, 2.629],
    rotation: [10.117, -3.658, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.315, 0.427, 2.588],
    rotation: [9.957, -3.878, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.431, 0.463, 2.481],
    rotation: [9.797, -4.008, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.452, 0.623, 2.503],
    rotation: [9.727, -4.008, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.418, 0.707, 2.602],
    rotation: [10.277, -4.008, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.385, 0.763, 2.757],
    rotation: [10.727, -3.698, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.304, 0.645, 2.711],
    rotation: [9.967, -3.988, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.209, 0.783, 2.948],
    rotation: [10.487, -3.458, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.339, 0.839, 2.964],
    rotation: [10.457, -3.348, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.182, 0.846, 3.052],
    rotation: [10.347, -3.528, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.241, 0.926, 3.13],
    rotation: [10.317, -3.358, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.316, 0.923, 3.103],
    rotation: [10.507, -3.308, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.316, 1.01, 3.314],
    rotation: [10.477, -3.048, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.418, 1.046, 3.368],
    rotation: [10.367, -3.108, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.471, 1.006, 3.265],
    rotation: [10.697, -3.428, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.518, 1.085, 3.386],
    rotation: [9.697, -3.838, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.427, 1.121, 3.439],
    rotation: [9.707, -3.398, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.474, 1.215, 3.442],
    rotation: [9.577, -3.518, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.549, 1.413, 3.449],
    rotation: [9.767, -3.738, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.623, 1.414, 3.362],
    rotation: [9.757, -4.188, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.693, 1.509, 3.1],
    rotation: [10.377, -4.438, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.667, 1.359, 3.081],
    rotation: [10.507, -4.558, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.626, 1.217, 3.104],
    rotation: [11.307, -4.338, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.571, 1.133, 3.317],
    rotation: [10.247, -4.288, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.586, 1.09, 3.207],
    rotation: [10.547, -4.338, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.579, 0.949, 2.914],
    rotation: [10.447, -4.148, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.567, 0.894, 2.854],
    rotation: [10.537, -3.998, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.485, 0.85, 2.872],
    rotation: [10.647, -3.608, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.47, 0.809, 2.795],
    rotation: [10.737, -3.418, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.625, 0.858, 2.623],
    rotation: [10.607, -3.938, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.643, 0.85, 2.546],
    rotation: [10.697, -3.838, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.687, 1.011, 2.62],
    rotation: [9.957, -4.378, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.687, 1.277, 2.715],
    rotation: [10.767, -4.598, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.688, 1.395, 2.603],
    rotation: [11.657, -4.668, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.695, 1.332, 2.421],
    rotation: [11.657, -4.668, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.671, 1.536, 2.374],
    rotation: [11.657, -4.668, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.693, 1.017, 2.415],
    rotation: [11.407, -4.628, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.694, 1.018, 2.275],
    rotation: [10.567, -4.528, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.619, 0.768, 2.384],
    rotation: [10.487, -3.978, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.599, 0.605, 2.331],
    rotation: [10.147, -4.078, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.597, 0.611, 2.175],
    rotation: [11.217, -4.328, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.541, 0.424, 2.213],
    rotation: [11.527, -4.508, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.467, 0.213, 2.307],
    rotation: [10.017, -4.068, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.466, 0.107, 2.258],
    rotation: [10.527, -4.338, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.429, 0.083, 2.072],
    rotation: [11.067, -4.298, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  //10th section
  {
    position: [0.443, 0.248, 1.887],
    rotation: [11.137, -4.358, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.433, 0.254, 1.745],
    rotation: [11.237, -4.128, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.496, 0.38, 1.91],
    rotation: [10.897, -4.518, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.605, 0.693, 1.757],
    rotation: [11.167, -3.978, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.753, 0.862, 1.768],
    rotation: [11.607, -4.268, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.758, 0.956, 1.708],
    rotation: [12.107, -4.538, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.755, 1.067, 1.672],
    rotation: [12.627, -4.538, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.749, 1.186, 1.712],
    rotation: [13.167, -4.238, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.778, 1.274, 1.954],
    rotation: [12.927, -4.938, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.779, 1.405, 1.954],
    rotation: [12.497, -4.818, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.774, 1.524, 1.97],
    rotation: [12.417, -4.968, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.763, 1.504, 1.814],
    rotation: [12.177, -4.478, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.73, 1.325, 1.456],
    rotation: [12.567, -5.008, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.748, 1.403, 1.355],
    rotation: [13.497, -4.858, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.748, 1.383, 1.228],
    rotation: [14.327, -4.848, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.755, 1.207, 1.226],
    rotation: [16.237, -4.818, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.77, 1.057, 1.226],
    rotation: [17.007, -4.818, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.764, 1.057, 1.357],
    rotation: [16.117, -4.688, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.755, 0.99, 1.458],
    rotation: [16.047, -4.628, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.704, 0.857, 1.524],
    rotation: [17.397, -4.218, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.699, 0.857, 1.366],
    rotation: [17.377, -4.228, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.747, 0.936, 1.193],
    rotation: [16.747, -4.248, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.682, 0.842, 1.217],
    rotation: [17.067, -4.078, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.606, 0.721, 1.199],
    rotation: [17.097, -4.148, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.597, 0.699, 1.344],
    rotation: [17.497, -3.938, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.511, 0.519, 1.398],
    rotation: [17.737, -4.308, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.472, 0.414, 1.173],
    rotation: [16.427, -4.248, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.422, 0.266, 1.406],
    rotation: [17.677, -4.248, 2.842],
    args: [0.035, 0.05, 32],
    type: "unused",
  },
  {
    position: [0.291, 0.045, 1.444],
    rotation: [17.807, -4.688, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.311, 0.09, 1.653],
    rotation: [17.947, -4.598, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  // 11th section
  {
    position: [0.459, 0.132, 0.993],
    rotation: [16.187, -4.138, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.555, 0.373, 0.96],
    rotation: [16.417, -4.248, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.509, 0.096, 0.764],
    rotation: [17.177, -4.328, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.58, 0.257, 0.721],
    rotation: [17.257, -4.418, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.616, 0.461, 0.879],
    rotation: [16.467, -4.278, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.652, 0.503, 0.748],
    rotation: [16.837, -4.408, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.624, 0.634, 0.992],
    rotation: [16.407, -4.298, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.682, 0.73, 0.918],
    rotation: [16.547, -4.288, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.719, 0.806, 0.876],
    rotation: [16.597, -4.258, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.777, 0.912, 0.902],
    rotation: [16.787, -3.978, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },

  {
    position: [0.815, 1.253, 0.756],
    rotation: [17.417, -4.688, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.783, 0.956, 1.006],
    rotation: [17.117, -4.288, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.907, 0.057, 0.336],
    rotation: [15.667, -3.488, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [1.051, 0.078, 0.161],
    rotation: [15.927, -4.528, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.927, 0.029, -0.27],
    rotation: [15.697, -5.798, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [1.084, 0.213, -0.167],
    rotation: [15.997, -5.628, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [1.117, 0.422, -0.076],
    rotation: [15.997, -5.628, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [1.135, 0.781, -0.072],
    rotation: [15.687, -5.458, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [1.058, 1.071, -0.124],
    rotation: [15.987, -5.468, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.895, 1.223, -0.221],
    rotation: [16.957, -5.588, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.815, 1.074, -0.393],
    rotation: [15.827, -5.318, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.76, 0.675, -0.457],
    rotation: [17.217, -4.788, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.454, 1.039, -0.772],
    rotation: [16.127, -4.928, 2.842],
    args: [0.015, 0.03, 32],
    type: "unused",
  },
  {
    position: [0.566, 0.675, -0.678],
    rotation: [16.007, -6.228, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.589, 0.089, -0.704],
    rotation: [15.717, -6.238, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.656, 0.066, -0.651],
    rotation: [15.227, -4.938, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
  {
    position: [0.71, 0.108, -0.408],
    rotation: [15.417, -5.078, 2.842],
    args: [0.025, 0.04, 32],
    type: "unused",
  },
];

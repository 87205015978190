import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import API_URL from "../Api";
import BoulderProblemList from "./BoulderProblemList";

export default function MyBoulderProblems({ user }) {
  const [listOfProblems, setListOfProblems] = useState(undefined);

  useEffect(() => {
    const session = user.signInUserSession;
    fetch(`${API_URL}/myBoulderProblems`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: session.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setListOfProblems(data);
      })
      .catch((error) => console.error(error));
  }, []); // eslint-disable-line
  
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: "500px", padding: "10px" }}>
        {listOfProblems ? (
          <BoulderProblemList
            listOfProblems={listOfProblems}
            title="My boulder problems"
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

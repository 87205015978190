import React, { useState } from "react";
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
} from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import History from "./utils/History";
import BoulderProblem from "./components/BoulderProblemDetails";
import CreateBoulderProblem from "./components/CreateBoulderProblem";
import logo from "./static/logo.png";
import logoSmall from "./static/logoSmall.png";
import AccountMenu from "./components/AccountMenu";
import MyBoulderProblems from "./components/MyBoulderProblems";

import { NotificationsContext } from "./contexts/NotificationsContext";
import PrivacyPolicy from "./static/PrivacyPolicy";
import CookiesPolicy from "./static/CookiesPolicy";
import Terms from "./static/Terms";
import NotFound from "./static/NotFound";
import AllBoulderProblems from "./components/AllBoulderProblems";

const useStyles = makeStyles({
  smallLogo: {
    padding: "10px",
    width: "100px",
    minWidth: "80px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  logo: {
    padding: "10px 0px 0px 0px",
    width: "20%",
    minWidth: "250px",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const RouterSwitch = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [notifications, setNotifications] = useState([]);
  const notification = (notification) => {
    setNotifications((prevState) => [...prevState, notification]);
    setTimeout(() => {
      closeNotification(notification);
    }, 5000);
  };

  const closeNotification = (item) => {
    setNotifications(notifications.filter((value, i) => value !== item));
  };

  const isMobile = useMediaQuery(`(max-width: 760px)`);

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#13daf0",
          display: "flex",
          justifyContent: "center",
          flex: "0 1 auto",
        }}
      >
        {user && (
          <AccountMenu
            style={{ position: "absolute", top: 1, right: 1 }}
            user={user}
            signOut={signOut}
          />
        )}
        {isMobile && user ? (
          <img
            className={classes.smallLogo}
            src={logoSmall}
            alt="logo"
            onClick={() => history.push("/")}
          />
        ) : (
          <img
            src={logo}
            className={classes.logo}
            alt="logo"
            onClick={() => history.push("/")}
          />
        )}
      </div>
      <div style={{ flex: "1 1 auto", width: "100%", overflow: "auto" }}>
        <NotificationsContext.Provider value={{ notification }}>
          <Switch>
            <Route exact path="/" component={AllBoulderProblems} />
            <Route
              exact
              path="/create/"
              component={() => {
                return (
                  <Authenticator
                    loginMechanisms={["email"]}
                    socialProviders={["google"]}
                  >
                    {({ user }) => <CreateBoulderProblem user={user} />}
                  </Authenticator>
                );
              }}
            />
            <Route path="/route/:uuid/" component={BoulderProblem} />
            <Route exact path="/cookies-policy" component={CookiesPolicy} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={Terms} />
            <Route
              exact
              path="/my-boulder-problems"
              component={() => {
                return (
                  <Authenticator
                    loginMechanisms={["email"]}
                    socialProviders={["google"]}
                  >
                    {({ user }) => <MyBoulderProblems user={user} />}
                  </Authenticator>
                );
              }}
            />
            <Route path="/" component={NotFound} />
          </Switch>
        </NotificationsContext.Provider>
      </div>
    </>
  );
};

const Router = () => (
  <BrowserRouter history={History}>
    <RouterSwitch />
  </BrowserRouter>
);

export default Router;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import API_URL from "../Api";
import { withStyles } from "@material-ui/core/styles";
import CommonStyles from "../utils/CommonStyles";
import ReactGA from "react-ga4";
import BoulderProblemList from "./BoulderProblemList";

const styles = (theme) => ({
  ...CommonStyles(theme),
  listOfBoulders: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

function AllBoulderProblems({ classes }) {
  const history = useHistory();
  const [listOfProblems, setListOfProblems] = useState(undefined);

  useEffect(() => {
    fetch(`${API_URL}/boulderProblems`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setListOfProblems(data);
      })
      .catch((error) => console.error(error));
  }, []);
  const redirectToCreate = () => {
    ReactGA.event({
      category: "Creation of boulder",
      action: "Create new boulder",
    });
    history.push(`create/`);
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: "500px", padding: "10px" }}>
        <Paper
          style={{
            padding: "5px",
            backgroundColor: "red",
          }}
        >
          <Typography style={{ color: "white" }}>
            BETA TESTING - All created problems might be deleted
          </Typography>
        </Paper>

        {listOfProblems ? (
          <div>
            <Button
              variant="outlined"
              onClick={() => redirectToCreate()}
              style={{
                width: "100%",
                margin: "10px 0px",
                backgroundColor: "white",
              }}
            >
              Create new boulder problem
            </Button>
            <BoulderProblemList listOfProblems={listOfProblems} title="All boulders" />
          </div>
        ) : (
          <div className={classes.centered}>
            <CircularProgress />
          </div>
        )}
        <Paper
          style={{
            padding: "5px",
            margin: "20px 0",
          }}
        >
          <Typography>
            In case of any problems or suggestions reach me on email:{" "}
            <a href="mailto:radek.wielonski@gmail.com">
              radek.wielonski@gmail.com
            </a>
          </Typography>
        </Paper>
      </div>
    </div>
  );
}

export default withStyles(styles)(AllBoulderProblems);

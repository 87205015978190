const CommonStyles = (theme) => ({
  formControl: {
    minWidth: 150,
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});
export default CommonStyles;

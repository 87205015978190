import React from "react";
import { Paper } from "@material-ui/core";

export default function Terms() {
  return (
    <Paper style={{ margin: "10px", padding: "10px" }}>
      <p class="MuiTypography-root jss84 MuiTypography-body1">
        <b>I. GENERAL PROVISIONS</b>
        <div class="MuiBox-root jss96 jss85">
          1. These Regulations define the rules of operation and conditions of
          use of the wspinappka.pl website, hereinafter referred to as the
          Website. The Regulations define the rights and obligations of its
          Users and the Website administrator in the provision of electronic
          services.
        </div>
        <div class="MuiBox-root jss97 jss85">
          2. "Website" means the website at wspinappka.pl along with all its
          subpages and resources.
        </div>
        <div class="MuiBox-root jss98 jss85">
          3. The website administrator is the company:
        </div>
        ----------------------------------
        <div class="MuiBox-root jss99 jss85">
          4. A "user" is any person who visits the websites gathered within the
          Website and uses its resources.
        </div>
        <div class="MuiBox-root jss100 jss85">
          5. The administrator reserves the right to limit access to some
          content or functions of the website according to the criteria adopted
          by him, in particular making them dependent on registering an account,
          paying a fee and other circumstances indicated by him.
        </div>
        <div class="MuiBox-root jss101 jss85">
          6. The law applicable to all legal relations arising from these
          Regulations is Polish law. Disputes will be settled by Polish courts
          competent for a given case.
        </div>
        <div class="MuiBox-root jss102 jss85">
          7. The operation of the Website is subject to Polish law. Disputes
          arising from the use of the website will be settled by Polish courts
          competent for a given case.
        </div>
        <div class="MuiBox-root jss103 jss85">
          8. In matters not covered by these regulations, the provisions of
          Polish law shall apply, in particular the Civil Code and other acts.
        </div>
        <div class="MuiBox-root jss104 jss85">
          9. The website provides services via the Internet.
        </div>
        <b>II. Services provided</b>
        <div class="MuiBox-root jss105 jss85">
          1. The website provide tool for routesetting and viewing routes on
          Warsaw Boulder.
        </div>
        <div class="MuiBox-root jss106 jss85">
          2. The Website makes every effort to ensure that the data provided on
          the Website is consistent and integral and free of errors.
        </div>
        <div class="MuiBox-root jss110 jss85">
          3. The user accepts the fact that he has no right to make any claims
          resulting from the malfunction of the website.
        </div>
        <div class="MuiBox-root jss111 jss85">
          4. The website may be used only for purposes consistent with
          applicable law.
        </div>
        <b>III. TERMS AND CONDITIONS OF USING THE WEBSITE</b>
        <div class="MuiBox-root jss112 jss85">
          1. Users can use selected Services provided by the website free of
          charge.
        </div>
        <div class="MuiBox-root jss113 jss85">
          2. Registered users can extend the number of free services provided by
          the website for free.
        </div>
        <b>IV. TECHNICAL CONDITIONS FOR THE USE OF THE WEBSITE</b>
        <div class="MuiBox-root jss118 jss85">
          1. To use the Website, it is necessary to have an electronic device
          (eg a computer, telephone, tablet, TV and others) equipped with a
          possibly up-to-date web browser. The website is not responsible if the
          User's device is not able to properly broadcast the content.
        </div>
        <div class="MuiBox-root jss119 jss85">
          2. Access to some of the Website's functions may depend on the
          installation of additional external plug-ins that enable the support
          of standards such as Flash, Silverlight, Java and others.
        </div>
        <div class="MuiBox-root jss120 jss85">
          3. Access to some of the Website's functions may depend on the
          necessity to have an account on the Website or an external service
          (Google, Facebook).
        </div>
        <b>V. FINAL PROVISIONS</b>
        <div class="MuiBox-root jss121 jss85">
          1. These regulations become effective as of the moment of their
          publication on the website.
        </div>
        <div class="MuiBox-root jss122 jss85">
          2. Date of the last update of the regulations: May 03, 2022.
        </div>
        <div class="MuiBox-root jss123 jss85">
          3. Using the Website, both by the User and the Registered User, is
          tantamount to accepting the regulations.
        </div>
      </p>
    </Paper>
  );
}

import React, { useState, useEffect } from "react";
import { Typography, CircularProgress, Paper } from "@material-ui/core";
import Model3D from "./Model3d";
import { Hold, BoulderProblem } from "../objects";
import API_URL from "../Api";
import { withStyles } from "@material-ui/core/styles";
import CommonStyles from "../utils/CommonStyles";
import { holdTypes } from "../objects";
import { grades } from "../constants";

const styles = (theme) => ({
  ...CommonStyles(theme),
  info: {
    margin: "5px",
    padding: "1px 5px",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
});

function BoulderProblemDetails({ classes, match }) {
  const [boulderProblem, setBoulderProblem] = useState(undefined);
  const [cameraPosition, setCameraPosition] = useState([5, 2, 3]);
  const [orbitPosition, setOrbitPosition] = useState(undefined)
  const createSquare = (name, color) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 5px",
          backgroundColor: "#ebebeb",
        }}
      >
        <Typography>{name}</Typography>
        <div
          className="square"
          style={{
            backgroundColor: color,
            width: "15px",
            height: "15px",
            margin: "5px",
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    fetch(`${API_URL}/boulderProblems/${match.params.uuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const response = data;
        const holds = response.holds.map(
          (x) =>
            new Hold(
              JSON.parse("[" + x.position + "]"),
              JSON.parse("[" + x.rotation + "]"),
              JSON.parse("[" + x.args + "]"),
              x.type
            )
        );

        const avgPosition0 =
          holds.map((x) => x.position[0]).reduce((a, b) => a + b, 0) /
          holds.length;
          const avgPosition1 =
          holds.map((x) => x.position[1]).reduce((a, b) => a + b, 0) /
          holds.length;
        const avgPosition2 =
          holds.map((x) => x.position[2]).reduce((a, b) => a + b, 0) /
          holds.length;
        setCameraPosition([avgPosition0 > 0 ? 5 : -5, 2, 2 * avgPosition2]);
        setOrbitPosition([avgPosition0, avgPosition1, avgPosition2])
        setBoulderProblem(
          new BoulderProblem(
            holds,
            response.name,
            response.grade,
            response.lastIsHold,
            response.author
          )
        );
      })
      .catch((error) => console.error(error));
  }, []); // eslint-disable-line

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      {boulderProblem ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "95%",
          }}
        >
          <Paper
            style={{
              position: "relative",
              margin: "10px 0px",
              flex: "1",
            }}
          >
            <Model3D
              boulderProblem={boulderProblem}
              type="readOnly"
              cameraPosition={cameraPosition}
              orbitPosition={orbitPosition}
            ></Model3D>
            <Paper style={{ position: "absolute", bottom: "0", right: "0" }}>
              {boulderProblem.lastIsHold &&
                createSquare("Top hold:", holdTypes.end)}
              {createSquare("Start hold/s:", holdTypes.start)}
              {createSquare("Available holds:", holdTypes.available)}
            </Paper>
          </Paper>
          <Paper
            style={{ margin: "10px 0px", padding: "5px", minHeight: "20%" }}
          >
            {!boulderProblem.lastIsHold && (
              <Typography className={classes.infoContainer}>
                <Paper style={{ padding: "1px 5px" }}>
                  <Typography style={{ color: "red" }}>No top hold</Typography>
                </Paper>
              </Typography>
            )}

            <Typography className={classes.infoContainer}>
              Name:
              <Paper className={classes.info}>{boulderProblem.name}</Paper>
            </Typography>

            <Typography className={classes.infoContainer}>
              Grade:
              <Paper className={classes.info}>V{boulderProblem.grade}/{grades[boulderProblem.grade]} </Paper>
            </Typography>
            <Typography className={classes.infoContainer}>
              Author:
              <Paper className={classes.info}>{boulderProblem.author} </Paper>
            </Typography>
          </Paper>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(BoulderProblemDetails);

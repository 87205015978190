import * as THREE from "three";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import HoldHighlights from "./HoldHighlights";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useTexture } from "@react-three/drei";
import { Suspense, useMemo, useRef, useState, useEffect } from "react";
import { a } from "react-spring/three";
import { holdsJson } from "../constants";

function Wall() {
  const texture = useTexture("/assets/wall_color.jpg");
  const obj = useLoader(OBJLoader, "https://wspinappka.s3.eu-central-1.amazonaws.com/wall.obj");
  const geometry = useMemo(() => {
    let g;
    obj.traverse((c) => {
      if (c.type === "Mesh") {
        const _c = c;
        g = _c.geometry;
      }
    });
    return g;
  }, [obj]);
  //useLoader.preload("/wall.obj")
  return (
    <Suspense fallback={null}>
    <mesh geometry={geometry} scale={1}>
      <meshPhysicalMaterial map={texture} />
    </mesh>
    </Suspense>
  );
}

function Plane() {
  return (
    <mesh position={[0, -0.005, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <boxBufferGeometry attach="geometry" args={[4, 7, 0.01]} />
      <meshLambertMaterial attach="material" color="grey" />
    </mesh>
  );
}

function Model() {
  return (
    <>
      <Wall />
      <Plane />
    </>
  );
}

function Lights() {
  return (
    <>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 15, 10]} angle={0.3} />
    </>
  );
}

// Hook
function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // eslint-disable-line
  return keyPressed;
}

function Model3d({ type, boulderProblem, cameraPosition, orbitPosition }) {
  // eslint-disable-line
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState(holdsJson.at(-1).position);
  const [rotation, setRotation] = useState(holdsJson.at(-1).rotation);
  const [args, setArgs] = useState([0.035, 0.05, 32]);
  const posUp = useKeyPress("q");
  const posDown = useKeyPress("a");
  const posLeft = useKeyPress("w");
  const posRight = useKeyPress("s");
  const posR = useKeyPress("e");
  const posF = useKeyPress("d");
  const rot11 = useKeyPress("r");
  const rot12 = useKeyPress("f");
  const rot21 = useKeyPress("t");
  const rot22 = useKeyPress("g");
  const rot31 = useKeyPress("y");
  const rot32 = useKeyPress("h");
  const sizeUp = useKeyPress("z");
  const sizeDown = useKeyPress("x");
  const printt = useKeyPress("p");
  // eslint-disable-next-line
  function MoveHold() {
    const mesh = useRef();

    // useFrame allows us to re-render/update rotation on each frame
    useFrame(() => {
      if (posUp) {
        setPosition((value) => {
          value[1] += 0.001;
          return value;
        });
      }
      if (posDown) {
        setPosition((value) => {
          value[1] -= 0.001;
          return value;
        });
      }
      if (posLeft) {
        setPosition((value) => {
          value[0] += 0.001;
          return value;
        });
      }
      if (posRight) {
        setPosition((value) => {
          value[0] -= 0.001;
          return value;
        });
      }
      if (posF) {
        setPosition((value) => {
          value[2] += 0.001;
          return value;
        });
      }
      if (posR) {
        setPosition((value) => {
          value[2] -= 0.001;
          return value;
        });
      }
      if (rot11) {
        setRotation((value) => {
          value[0] -= 0.01;
          return value;
        });
      }
      if (rot12) {
        setRotation((value) => {
          value[0] += 0.01;
          return value;
        });
      }

      if (rot21) {
        setRotation((value) => {
          value[1] -= 0.01;
          return value;
        });
      }
      if (rot22) {
        setRotation((value) => {
          value[1] += 0.01;
          return value;
        });
      }

      if (rot31) {
        setRotation((value) => {
          value[2] -= 0.01;
          return value;
        });
      }
      if (rot32) {
        setRotation((value) => {
          value[2] += 0.01;
          return value;
        });
      }
      if (!show) {
        if (sizeUp) {
          setShow(true);
          setArgs((value) => {
            value[1] += 0.005;
            value[0] += 0.005;
            return value;
          });
          setTimeout(() => setShow(false), 1000);
        }

        if (sizeDown) {
          setShow(true);
          setArgs((value) => {
            value[1] -= 0.005;
            value[0] -= 0.005;
            return value;
          });
          setTimeout(() => setShow(false), 1000);
        }
        if (printt) {
          setShow(true);
          console.log({
            position: position.map((x) => parseFloat(x.toFixed(3))),
            rotation: rotation.map((x) => parseFloat(x.toFixed(3))),
            args: args.map((x) => parseFloat(x.toFixed(3))),
            type: "unused",
          });
          setTimeout(() => setShow(false), 1000);
        }
      }
    });

    const x = {
      position: position,
      rotation: rotation,
      args: args,
      type: "unused",
      color: "red",
    };
    let tempArgs = [...x.args];
    tempArgs[0] = 0;
    const tempGeometry = new THREE.RingBufferGeometry(...tempArgs);
    const geometry = new THREE.RingBufferGeometry(...x.args);
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(x.color),
      side: THREE.FrontSide, // TODO change to FrontSide
    });

    return (
      <>
        <a.mesh
          ref={mesh}
          position={x.position}
          rotation={x.rotation}
          geometry={tempGeometry}
          material={
            new THREE.MeshBasicMaterial({
              transparent: true,
              opacity: 0.0,
              side: THREE.FrontSide,
            })
          }
        />

        <a.mesh
          ref={mesh}
          position={x.position}
          rotation={x.rotation}
          geometry={geometry}
          material={material}
        />
      </>
    );
  }
  return (
    <Canvas camera={{ fov: 45, position: cameraPosition }}>
      <OrbitControls target={orbitPosition} />
      <Lights />
      <Suspense
        fallback={
          <Html>
            <div>loading...</div>
          </Html>
        }
      >
        <Model />
        {/* <MoveHold /> */}
        <HoldHighlights boulderProblem={boulderProblem} type={type} />
      </Suspense>
    </Canvas>
  );
}

export default Model3d;
